export function convertTime(input) {
  const timePart = input.substring(11, 16); // Extract the time part of the string
  const timeComponents = timePart.split(":"); // Split the time into hours and minutes
  let hours = parseInt(timeComponents[0], 10); // Parse the hours part to get the integer value
  const minutes = timeComponents[1]; // Get the minutes part

  // Adjust the hours to 12-hour format and determine AM or PM
  let period = "AM";
  if (hours >= 12) {
    period = "PM";
    if (hours > 12) {
      hours -= 12;
    }
  }

  // Format the hours and minutes with leading zeros if necessary
  const formattedHours = hours.toString().padStart(2, "0");
  const output = `${formattedHours}:${minutes} ${period}`; // Create the desired output format

  return output;
}

export function bookAppointmentDate(appointmentBooked) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const date = new Date(appointmentBooked);

  return (
    months[date.getMonth()] +
    " " +
    getOrdinalDate(date) +
    ", " +
    date.getFullYear() +
    " at " +
    convertTime(appointmentBooked) +
    " "
  );
}

function getOrdinalDate(date) {
  const day = date.getDate();
  const suffixes = ["th", "st", "nd", "rd"];
  let suffix;

  if (day >= 11 && day <= 13) {
    suffix = "th";
  } else {
    suffix = suffixes[day % 10] || "th";
  }

  return day + suffix;
}

export function convertDateTimeToISO(selectedTime, selectedDate) {
  const timeComponents = selectedTime.split(":");
  let hours = parseInt(timeComponents[0]);
  const minutes = parseInt(timeComponents[1].split(" ")[0]);
  if (timeComponents[1].includes("PM") && hours < 12) {
    hours += 12;
  }
  const dateObj = new Date(selectedDate);

  const isoDateTimeString =
    dateObj.toISOString().substring(0, 10) +
    "T" +
    (hours < 10 ? "0" : "") +
    hours +
    ":" +
    (minutes < 10 ? "0" : "") +
    minutes +
    ":00";

  return isoDateTimeString;
}

export function toDateTime(timeString, dateObject) {
  if (dateObject) {
    const [hours, minutes] = timeString.split(":").map((str) => parseInt(str));
    const date = new Date(dateObject);
    date.setHours(hours + (timeString.includes("PM") && hours !== 12 ? 12 : 0));
    date.setMinutes(minutes);

    const dateString = date.toString();
    console.log("dateString:", dateString);
    return dateString;
  }
}
