import React, { useContext } from "react";
import styled from "styled-components";
import { DataContext, DataACTIONS } from "./context/DataContext";
import { APPOINTMENT_TYPE } from "../constants";
import { topicsDetailsObj } from "../copy";
import DdsLink from "./DdsLink";
import { useNavigate } from "react-router-dom";
import { bookAppointmentDate } from "../../helpers/util";

const StyledAppointmentDetails = styled.div`
  && {
    color: var(--secondary);
  }
`;
const CardStyle = styled.div`
  && {
    border: 1px solid var(--borderDarkGrey);
    padding: 16px;
    margin-top: 15px;
    border-radius: 8px;
    h1 {
      font-size: 20px;
      color: var(--secondary);
      margin-bottom: 8px;
    }
    h2 {
      font-size: 16px;
    }
    p {
      font-size: 16px;
      color: var(--secondary);
    }
    span {
      margin-top: 18px;
    }
    .dds-disclaimer {
      margin-top: 8px;
      color: var(--disclaimerBlue);
      font-size: 11px;
    }
    .headerWrapper {
      display: flex;
      justify-content: space-between;
    }
    .dds-preferred {
      display: flex;
      height: 24px;
      p:nth-child(2) {
        border: 1px solid var(--secondary);
        border-radius: 8px;
        padding: 2px 4px;
        color: var(--secondary);
        margin-left: 10px;
        vertical-aligh: middle;
        font-size: var(--fz-xs);
        padding-bottom: 25px;
      }
    }
  }
`;

const AppointmentDetails = ({ editlink }) => {
  const { dataState, dataDispatch } = useContext(DataContext);
  let navigate = useNavigate();
  function handleEdit(screen) {
    navigate(screen);
    dataDispatch({
      type: DataACTIONS.edittedUpdated,
      payload: true,
    });
  }

  const { appointmentBooked, vehInfo } = dataState;
  return (
    <StyledAppointmentDetails>
      <CardStyle>
        <div className="headerWrapper">
          <h1>What do you want your dealer to go over? </h1>
          {editlink && (
            <DdsLink
              type="primary"
              text="Edit"
              ariaLabel="dds-edit"
              action={() => handleEdit("/")}
            />
          )}
        </div>
        <h2>{APPOINTMENT_TYPE[dataState.appointmentType - 1]?.name}</h2>
        {topicsDetailsObj.map((obj) => obj.isChecked && <p>{obj.name}</p>)}
      </CardStyle>
      <CardStyle>
        <div className="headerWrapper">
          <h1>Pick up date and time </h1>
          {editlink && (
            <DdsLink
              type="primary"
              text="Edit"
              ariaLabel="dds-edit"
              action={() => handleEdit("/appointments")}
            />
          )}
        </div>

        <p>{bookAppointmentDate(appointmentBooked)}</p>
        <div className="dds-disclaimer">
          Pick up times are in the dealer's timezone
        </div>
      </CardStyle>
      <CardStyle>
        <h1>Your contact information </h1>
        <div data-cs-mask>
          <h2 data-cs-mask>{vehInfo?.customerName}</h2>
          <div className="dds-preferred">
            <p data-cs-mask>{vehInfo?.customerEmail}</p>
            <p>Preferred</p>
          </div>
          <p data-cs-mask>{vehInfo.customerPhone}</p>
        </div>
        <div className="dds-disclaimer">
          To edit your contact information, please return to your dashboard.
        </div>
      </CardStyle>
      <CardStyle>
        <h1>Pick up location </h1>
        <h2>{vehInfo.dealerName}</h2>
        <p>{vehInfo.dealerAddress?.addressLine1}</p>
        <p>{vehInfo.dealerAddress?.addressLine2}</p>
        <p>{vehInfo.dealerAddress?.addressLine3}</p>
        <p>{vehInfo.dealerAddress?.addressLine4}</p>
        <p>{vehInfo.dealerAddress?.city}</p>
        <p>{vehInfo.dealerAddress?.stateCode}</p>
        <p>{vehInfo.dealerAddress?.zip}</p>
        <p>{vehInfo.dealerAddress?.countryName}</p>
        <p>{vehInfo.dealerPhone}</p>
      </CardStyle>
    </StyledAppointmentDetails>
  );
};
export default AppointmentDetails;
