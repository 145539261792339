import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  AppContext,
  AppACTIONS,
} from "../components/common/context/AppContext";
import {
  DataContext,
  DataACTIONS,
} from "../components/common/context/DataContext";
import api from "../api";
import styled from "styled-components";
import DdsButton from "./common/DdsButton";
import StepHeader from "../components/common/StepHeader";
import { StepHeaders } from "../components/common/HeaderCopy";
import RadioButtonCard from "./common/RadioButtonCard";
import { UseRadio } from "./common/customhooks/forms";
import { topicsObj, topicsDetailsObj } from "./copy";
import { useNavigate } from "react-router-dom";
import VehInfo from "./common/VehInfo";
import { APPOINTMENT_TYPE } from "./constants";

import {
  pageLoadTags,
  onClick,
} from "../components/common/utils/analytics/index";

const StyledHome = styled.div`
  && {
    .dds-card-row {
      padding-top: 20px;
      width: 100%;
    }
    .dds-card-notes {
      width: 100%;
      display: flex;
      flex-direction: column;
      label {
        font-family: var(--fontPrimary);
        margin-left: 20px;
      }
      .dds-notes-input {
        padding-left: 20px;
        border-radius: 8px;
        font-family: var(--fontPrimary);
        color: var(--fordGrey);
      }
    }
    .dds-buttons-container {
      justify-content: flex-end;
    }
    textarea {
      resize: none;
    }
  }
`;
const Home = () => {
  const { appState, appDispatch } = useContext(AppContext);
  const { dataState, dataDispatch } = useContext(DataContext);
  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);

  let orderId = params.get("orderId") || appState.orderId;

  if (window.fma && !orderId) {
    orderId = window.fma.state;
  }

  const [curValue, setCurValue] = useState(0) || dataState.curValue;
  const [vehData, setVehData] = useState(dataState.vehInfo);
  const [curTopicsDetails, setCurTopicsDetails] = useState(topicsDetailsObj);

  const [notes, setNotes] = useState("");
  let navigate = useNavigate();
  const { model, year } = dataState.vehInfo;
  const nameplate = model
    ? model === "Mustang Mach-E"
      ? "ford mach-e"
      : "ford f-150 lightning"
    : "";
  const category = model === "Mustang Mach-E" ? "suv" : "lightning";

  function handleEdit() {
    if (curValue > 0) {
      dataDispatch({
        type: DataACTIONS.appointmentTypeUpdated,
        payload: curValue,
      });
    }
    dataDispatch({
      type: DataACTIONS.notesUpdated,
      payload: notes,
    });
    onClick.vehiclePickupReviewDetails(
      nameplate,
      year,
      model,
      "edit appt details"
    );
    navigate("/summary");
  }

  function handleHomeSubmit() {
    dataDispatch({
      type: DataACTIONS.notesUpdated,
      payload: notes,
    });
    dataDispatch({
      type: DataACTIONS.appointmentTypeUpdated,
      payload: curValue,
    });
    appDispatch({
      type: AppACTIONS.completedStepsUpdated,
      payload: 1,
    });
    let subcategory = "";
    let appType = curValue - 1;

    curTopicsDetails.forEach((val) => {
      if (val.isChecked) {
        subcategory = subcategory + ":" + val.name;
      }
    });

    onClick.vehiclePickUpStart(
      nameplate,
      year,
      model,
      "continue",
      APPOINTMENT_TYPE[appType]?.name,
      subcategory
    );
    navigate("/appointments");
  }

  function parseAppointmentData(data) {
    if (data.appointment) {
      dataDispatch({
        type: DataACTIONS.appointmentTypeUpdated,
        payload:
          APPOINTMENT_TYPE.findIndex(
            (e) => e.value === data.appointment.dealerInteraction
          ) + 1,
      });
      data.appointment.orientationTopics.forEach((value) => {
        curTopicsDetails.forEach((val) => {
          if (val.enumVal === value) {
            val.isChecked = true;
          }
        });
      });
      dataDispatch({
        type: DataACTIONS.appointmentBookedUpdated,
        payload: data.appointment.date,
      });
    }
    const topicsDetails = curTopicsDetails;
    dataDispatch({
      type: DataACTIONS.topicsDetailsUpdated,
      payload: { topicsDetails },
    });
    // const dateTime = new Date(data.appointment.date);
  }

  function checkEmptyAppt(obj) {
    for (let key in obj) {
      // If any array is not empty, return false
      if (
        obj.hasOwnProperty(key) &&
        Array.isArray(obj[key]) &&
        obj[key].length !== 0
      ) {
        return false;
      }
    }
    // If no non-empty arrays found, return true
    return true;
  }

  const updateData = (data) => {
    parseAppointmentData(data);
    dataDispatch({
      type: DataACTIONS.vehInfoUpdated,
      payload: { ...data },
    });
  };
  const updateOrder = () => {
    if (orderId && appState.accessToken) {
      dataDispatch({
        type: "SHOW_LOADING_SPINNER",
        payload: true,
      });
      // check for orderId
      appDispatch({
        type: AppACTIONS.orderIdUpdated,
        payload: orderId,
      });

      api
        .getOrders(orderId, appState.accessToken)
        .then((data) => {
          if (data) {
            setVehData(data);
            if (!checkEmptyAppt(data.upcomingAppointmentSlots)) {
              if (data.model.includes("Mustang")) {
                const macheTopics = [...topicsDetailsObj].slice(0, -1);
                setCurTopicsDetails(macheTopics);
              }
              if (data.appointment) {
                if (data.appointment.appointmentStatus === "COMPLETE") {
                  appDispatch({
                    type: AppACTIONS.appointmentStatusUpdated,
                    payload: data.appointment.appointmentStatus,
                  });

                  const dealerInfo = {
                    dealerName: data.dealerName,
                    dealerPhone: data.dealerPhone,
                  };
                  dataDispatch({
                    type: DataACTIONS.vehInfoUpdated,
                    payload: dealerInfo,
                  });
                  navigate("/completed");
                } else if (
                  data.appointment &&
                  data.appointment.dealershipEmployee &&
                  !dataState.appointmentBooked
                ) {
                  updateData(data);

                  navigate("/appointmentConfirmation");
                } else if (data.appointment && !dataState.appointmentBooked) {
                  updateData(data);
                  navigate("/appointmentRequest");
                }
              } else {
                updateData(data);

                pageLoadTags.vehiclePickUpStart(
                  data.model === "Mustang Mach-E"
                    ? "ford mach-e"
                    : "ford f-150 lightning",
                  data.model === "Mustang Mach-E" ? "suv" : "lightning",
                  data.model,
                  data.year
                );
              }
            } else {
              appDispatch({
                type: AppACTIONS.appointmentStatusUpdated,
                payload: "ERROR",
              });
              navigate("/error");
            }
            dataDispatch({
              type: "SHOW_LOADING_SPINNER",
              payload: false,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          appDispatch({
            type: AppACTIONS.appointmentStatusUpdated,
            payload: "ERROR",
          });
          dataDispatch({
            type: "SHOW_LOADING_SPINNER",
            payload: false,
          });
          navigate("/error");
        });
    } else {
      appDispatch({
        type: AppACTIONS.appointmentStatusUpdated,
        payload: "ERROR",
      });
      navigate("/error");
    }
  };

  function handleStandardTopics(e, appointType) {
    const eventVal = e.target.name;
    curTopicsDetails.forEach((val) => {
      if (val.name === eventVal) {
        val.isChecked = e.target.checked;
      }
      val.appointType = appointType;
    });
    const topicsDetails = curTopicsDetails;
    dataDispatch({
      type: DataACTIONS.topicsDetailsUpdated,
      payload: { topicsDetails },
    });
  }

  const tType = topicsObj.map((item) => UseRadio(item));

  function setChecked(ans) {
    setCurValue(ans);
  }

  useEffect(() => {
    if (Object.keys(vehData).length < 1) {
      updateOrder();
    } else {
      if (vehData.model.includes("Mustang")) {
        const macheTopics = [...topicsDetailsObj].slice(0, -1);
        setCurTopicsDetails(macheTopics);
      }
      setTimeout(function () {
        pageLoadTags.vehiclePickUpStart(
          nameplate,
          category,
          vehData.model,
          vehData.year
        );
      }, 1000);
    }
    appDispatch({
      type: AppACTIONS.currentStepUpdated,
      payload: 1,
    });
  }, [appDispatch]);

  return (
    <StyledHome>
      <StepHeader
        mainHead={StepHeaders.home.main}
        subHead={StepHeaders.home.sub}
        maxWidth="656px"
      />
      {vehData && <VehInfo data={vehData} />}
      {vehData && (
        <div className="dds-card-row">
          {tType.map((obj, i) => (
            <RadioButtonCard
              {...obj}
              id={i + 1}
              index={i + 1}
              setChecked={setChecked}
              curValue={curValue ? curValue : dataState.appointmentType}
              topicsDetailsObj={curTopicsDetails}
              handleStandardTopics={handleStandardTopics}
              key={i}
            />
          ))}
        </div>
      )}
      <div className="dds-card-notes">
        <label>Additional requests or comments?</label>
        <textarea
          name="textarea"
          rows="5"
          cols="40"
          placeholder="Any additional requests or comments such as your language preference that your EV expert should know for your appointment."
          className="dds-notes-input"
          value={notes || dataState.notes}
          onChange={(e) => {
            setNotes(e.target.value);
          }}
        ></textarea>
      </div>
      {dataState.editted === true ? (
        <div className="dds-edit-buttons-container">
          <DdsButton type="primary" text="Save" action={handleEdit} />
        </div>
      ) : (
        <div className="dds-buttons-container">
          <DdsButton
            type="primary"
            text="Continue"
            action={handleHomeSubmit}
            iconType="nextIcon"
            disabled={
              curValue === 0 && !dataState.appointmentType ? "disabled" : ""
            }
          />
        </div>
      )}
    </StyledHome>
  );
};

export default Home;
