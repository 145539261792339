import React, { useEffect, useContext } from "react";
import StepHeader from "./common/StepHeader";
import styled from "styled-components";
import { StepHeaders } from "../components/common/HeaderCopy";
import VehInfo from "./common/VehInfo";
import AppointmentDetails from "./common/AppointmentDetails";
import ScrollToTop from "./common/ScrollToTop";

import DdsButton from "./common/DdsButton";
import { useNavigate } from "react-router-dom";

import {
  AppContext,
  AppACTIONS,
} from "../components/common/context/AppContext";
import { DataContext } from "../components/common/context/DataContext";
import DdsLink from "./common/DdsLink";
import { getExitUrl } from "./constants";
import { bookAppointmentDate } from "../helpers/util";
import { mediaQueries } from "./common/mediaQueries";
import {
  pageLoadTags,
  onClick,
} from "../components/common/utils/analytics/index";

const StyledAppointmentRequest = styled.div`
  && {
    .dds-buttons-container {
      margin-top: 30px;
      flex-direction: column;
      @media ${mediaQueries.underM} {
        button {
          width: 100%;
          justify-content: center;
        }
      }
      button:nth-child(2) {
        margin-top: 15px;
      }
      a {
        margin-top: 15px;
        text-decoration: none;
      }
    }
    b:nth-of-type(2) {
      text-transform: lowercase;
    }
  }
`;

const AppointmentConfirmationPage = () => {
  let navigate = useNavigate();
  const { appState, appDispatch } = useContext(AppContext);
  const { dataState } = useContext(DataContext);

  function goToDashboard() {
    window.location.assign(getExitUrl(appState.orderId) + appState.orderId);
  }

  const dateTime = bookAppointmentDate(dataState.appointmentBooked);
  const email = dataState.vehInfo?.customerEmail;

  const { model, year } = dataState.vehInfo;
  const nameplate =
    model === "Mustang Mach-E" ? "ford mach-e" : "ford f-150 lightning";
  const category = model === "Mustang Mach-E" ? "suv" : "lightning";

  useEffect(() => {
    pageLoadTags.vehiclePickupAppointmentConfirmed(
      nameplate,
      category,
      model,
      year
    );
    appDispatch({
      type: AppACTIONS.currentStepUpdated,
      payload: 5,
    });
  }, [appDispatch]);

  function handleAction(action) {
    if (action === "dashboard") {
      onClick.vehiclePickupAppointmentConfirmed(
        nameplate,
        year,
        model,
        "back to dashboard"
      );
      goToDashboard();
    } else if (action === "edit") {
      onClick.vehiclePickupAppointmentConfirmed(
        nameplate,
        year,
        model,
        "edit appt"
      );
      navigate("/");
    } else {
      onClick.vehiclePickupAppointmentConfirmed(
        nameplate,
        year,
        model,
        "cancel appt"
      );
      navigate("/cancel");
    }
  }

  const subheader = (
    <>
      You are scheduled to pick up your vehicle on <b>{`${dateTime}`}.</b> A
      confirmation email was sent to{" "}
      <b className="dds_email_text" data-cs-mask>{`${email}`}.</b>
    </>
  );

  return (
    <StyledAppointmentRequest>
      <ScrollToTop />
      <StepHeader
        mainHead={StepHeaders.appointmentConfirmation.main}
        subHead={subheader}
      />
      <VehInfo data={dataState.vehInfo} />
      <AppointmentDetails />
      <div className="dds-buttons-container">
        <DdsButton
          type="primary"
          text="Return to dashboard"
          action={() => handleAction("dashboard")}
        />
        <DdsButton
          type="secondary"
          text="Edit appointment"
          ariaLabel="dds-reschedule-appointment"
          action={() => handleAction("edit")}
        />
        <DdsLink
          type="primary"
          text="Cancel appointment"
          ariaLabel="dds-cancel-appointment"
          action={() => handleAction("cancel")}
        />
      </div>
    </StyledAppointmentRequest>
  );
};
export default AppointmentConfirmationPage;
